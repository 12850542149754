/* eslint-disable curly */
import { Catalog } from '../../api/catalog';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InsiderTracking } from '~global/sociolla.dm.lib';
import { getRemoteConfig } from '~config/remoteConfig';
import { setSmartRecommendationPlacements } from './slice';

export const getProductsSmartRecommendation = createAsyncThunk(
  'insiderTracking/getProductsSmartRecommendation',
  async (id, { rejectWithValue }) => {
    try {
      if (!id) return rejectWithValue('Invalid recommendation ID');

      const listProductsId = await Promise.race([
        InsiderTracking.insiderGetSmartRecommendation(id),
        new Promise((_, reject) =>
          setTimeout(() => reject('Recommendation timeout'), 5000),
        ),
      ]);

      if (!listProductsId?.length) return [];

      const response = await Catalog.getBestSellerLoadProductsRequest({
        filter: { id: { $in: listProductsId } },
      });

      return response.data.data || [];
    } catch (error) {
      return rejectWithValue(
        error.message || 'Failed to fetch recommendations',
      );
    }
  },
);

export const processSmartRecommendations = createAsyncThunk(
  'insiderTracking/processSmartRecommendations',
  async (_, { dispatch, getState }) => {
    const { smartRecommendationCampaignPlacements, processedCampaignIds = [] } =
      getState().insiderTracking;

    if (!smartRecommendationCampaignPlacements) return;

    const uniqueCampaignIDs = [
      ...new Set(
        Object.values(smartRecommendationCampaignPlacements)
          .flatMap((item) => item.campaignID)
          .filter(Boolean),
      ),
    ];

    const unprocessedIds = uniqueCampaignIDs.filter(
      (id) => !processedCampaignIds.includes(id),
    );

    if (unprocessedIds.length === 0) return;

    await Promise.all(
      unprocessedIds.map((id) => dispatch(getProductsSmartRecommendation(id))),
    );
  },
);

export const getRemoteConfigSmartRecommendation = createAsyncThunk(
  'insiderTracking/getRemoteConfigSmartRecommendation',
  async (_, { dispatch }) => {
    try {
      const { configStatus, configData } = await getRemoteConfig();

      if (!configStatus || !configData) return [];

      const placements = JSON.parse(configData);

      dispatch(setSmartRecommendationPlacements(placements));

      await new Promise((resolve) => setTimeout(resolve, 0));

      await dispatch(processSmartRecommendations());

      return placements;
    } catch (error) {
      console.error('Error in getRemoteConfigSmartRecommendation:', error);
      return [];
    }
  },
);
