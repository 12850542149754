import { createSlice } from '@reduxjs/toolkit';
import {
  getProductsSmartRecommendation,
  getRemoteConfigSmartRecommendation,
} from './thunks';

const initialState = {
  listSmartRecommendationProducts: [],
  smartRecommendationCampaignPlacements: null,
  processedCampaignIds: [],
  status: {
    listSmartRecommendationProducts: 'idle',
    smartRecommendationCampaignPlacements: 'idle',
  },
};

const insiderTrackingSlice = createSlice({
  name: 'insiderTracking',
  initialState,
  reducers: {
    setSmartRecommendationPlacements: (state, action) => {
      state.smartRecommendationCampaignPlacements = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsSmartRecommendation.pending, (state) => {
        state.status.listSmartRecommendationProducts = 'loading';
      })
      .addCase(
        getProductsSmartRecommendation.fulfilled,
        (state, { payload, meta }) => {
          const idParams = meta.arg;
          const existingIndex = state.listSmartRecommendationProducts.findIndex(
            (item) => item.recomendationId === idParams,
          );

          const productsRecommendation = payload.map((item) => ({
            ...item,
            recomendationId: idParams,
          }));

          const newRecoData = {
            recomendationId: idParams,
            data: productsRecommendation,
          };

          if (existingIndex !== -1) {
            state.listSmartRecommendationProducts[existingIndex] = newRecoData;
          } else {
            state.listSmartRecommendationProducts.push(newRecoData);
          }

          state.status.listSmartRecommendationProducts = 'succeeded';
        },
      )
      .addCase(getProductsSmartRecommendation.rejected, (state) => {
        state.status.listSmartRecommendationProducts = 'error';
      })
      .addCase(getRemoteConfigSmartRecommendation.pending, (state) => {
        state.status.smartRecommendationCampaignPlacements = 'loading';
      })
      .addCase(
        getRemoteConfigSmartRecommendation.fulfilled,
        (state, { payload }) => {
          state.smartRecommendationCampaignPlacements = payload;
          state.status.smartRecommendationCampaignPlacements = 'succeeded';
        },
      )
      .addCase(getRemoteConfigSmartRecommendation.rejected, (state) => {
        state.status.smartRecommendationCampaignPlacements = 'error';
      });
  },
});

export const { setSmartRecommendationPlacements, resetState } =
  insiderTrackingSlice.actions;
export default insiderTrackingSlice.reducer;
